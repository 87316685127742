@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  src: url(/static/media/Inter-Thin-BETA.95dcd983.woff2) format("woff2"),
       url(/static/media/Inter-Thin-BETA.993430a0.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  src: url(/static/media/Inter-ThinItalic-BETA.bab091cd.woff2) format("woff2"),
       url(/static/media/Inter-ThinItalic-BETA.03aaefea.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  src: url(/static/media/Inter-ExtraLight-BETA.3e86dd6b.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLight-BETA.7410d537.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  src: url(/static/media/Inter-ExtraLightItalic-BETA.233a2dea.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLightItalic-BETA.2ab0bd66.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  src: url(/static/media/Inter-Light-BETA.42922cef.woff2) format("woff2"),
       url(/static/media/Inter-Light-BETA.235041ef.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  src: url(/static/media/Inter-LightItalic-BETA.ba0b2a0c.woff2) format("woff2"),
       url(/static/media/Inter-LightItalic-BETA.7547f5c1.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"),
       url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  src: url(/static/media/Inter-Italic.381444ec.woff2) format("woff2"),
       url(/static/media/Inter-Italic.35cf8109.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url(/static/media/Inter-Medium.2e5e0884.woff2) format("woff2"),
       url(/static/media/Inter-Medium.c09fb389.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  src: url(/static/media/Inter-MediumItalic.7a7fd735.woff2) format("woff2"),
       url(/static/media/Inter-MediumItalic.ad6e093c.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff2"),
       url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBoldItalic.57262d35.woff2) format("woff2"),
       url(/static/media/Inter-SemiBoldItalic.569061c2.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url(/static/media/Inter-Bold.34356f6b.woff2) format("woff2"),
       url(/static/media/Inter-Bold.61c493e3.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  src: url(/static/media/Inter-BoldItalic.57acb479.woff2) format("woff2"),
       url(/static/media/Inter-BoldItalic.6fbcf86a.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBold.a627e2ce.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBold.0b03428a.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBoldItalic.b95f0df1.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBoldItalic.137fcd98.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url(/static/media/Inter-Black.c7a3bad1.woff2) format("woff2"),
       url(/static/media/Inter-Black.a4502329.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  src: url(/static/media/Inter-BlackItalic.d000d76e.woff2) format("woff2"),
       url(/static/media/Inter-BlackItalic.094751f4.woff) format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2 supports variations(gvar)"),
       url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2-variations"),
       url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2");
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url(/static/media/Inter-italic.var.74578302.woff2) format("woff2 supports variations(gvar)"),
       url(/static/media/Inter-italic.var.74578302.woff2) format("woff2-variations"),
       url(/static/media/Inter-italic.var.74578302.woff2) format("woff2");
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/

@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url(/static/media/Inter.var.6f521bf6.woff2) format("woff2-variations"),
       url(/static/media/Inter.var.6f521bf6.woff2) format("woff2");
}

